<div class="card mb-0">
  <div class="flex justify-content-between mb-3">
    <div>
      <span class="block text-500 font-semibold mb-3">{{ title }}</span>
      <div class="text-900 font-bold text-4xl">{{ count }}</div>
    </div>
    <div class="flex align-items-center justify-content-center bg-{{color}}-100 border-round"
         [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
      <i class="pi {{icon}} text-{{color}}-500 text-xl"></i>
    </div>
  </div>
  <span class="text-500 font-light">{{description}}</span>
</div>
