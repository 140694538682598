import {Component, Input} from '@angular/core';
import {NgStyle} from "@angular/common";

@Component({
  selector: 'bo-simple-card-count',
  templateUrl: './simple-card-count.component.html',
  imports: [
    NgStyle
  ],
  standalone: true
})
export class SimpleCardCountComponent {
  @Input({required: true}) title!: string;
  @Input({required: true}) count!: number;
  @Input({required: true}) icon!: string;
  @Input() description: string| undefined;
  @Input() color = 'blue';
}
