import {computed, inject, Injectable, Signal, signal} from "@angular/core";
import {EventFound, EventsService} from "@smatchy/sdk";

@Injectable({providedIn: 'root'})
export class EventStoreService {
  #eventApi = inject(EventsService);
  futureEvents = signal<EventFound[]>([]);
  pastEvents = signal<EventFound[]>([]);
  countFutureEvents: Signal<number>;
  countPastEvents: Signal<number>;

  // user = signal<FutureEvent | undefined>(undefined);

  constructor() {
    this.countFutureEvents = computed(() => this.futureEvents().length);
    this.countPastEvents = computed(() => this.pastEvents().length);

    this.#eventApi.filterEvents({
      filterEventsDto: {
        dates: [
          {
            startDate: new Date().toISOString()
          }
        ]
      }
    }).subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        this.futureEvents.set(res.value?.events || []);
      }

    });

    this.#eventApi.filterEvents({
      filterEventsDto: {
        dates: [
          {
            startDate: new Date("2000-01-01").toISOString(),
            endDate: new Date().toISOString()
          }
        ]
      }
    }).subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        this.pastEvents.set(res.value?.events || []);
      }

    })
  }
}
