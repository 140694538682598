import {computed, inject, Injectable, Signal, signal} from "@angular/core";
import {EventProps, GroupDetailsDto, UserResponse, UsersService} from "@smatchy/sdk";

@Injectable({providedIn: 'root'})
export class UserStoreService {
  #userApi = inject(UsersService);
  users = signal<UserResponse[]>([]);
  // @ts-ignore
  user = signal<UserResponse>(null);
  userEvents = signal<EventProps[]>([]);
  userGroups = signal<GroupDetailsDto[]>([]);
  countUsers: Signal<number>;

  constructor() {
    this.countUsers = computed(() => this.users().length);
    this.#userApi.list().subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        this.users.set(res.value?.users || []);
      }

    })
  }

  getUserEvents(userId: string) {
    this.#userApi.getEventsOfUser({userId}).subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        this.userEvents.set(res.value?.events || []);
      }
    })
  }

  getUserGroups(userId: string) {
    this.#userApi.getGroupsOfUser({userId}).subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        this.userGroups.set(res.value?.groups || []);
      }
    })
  }

  getUser(userId: string) {
    this.#userApi.getById({userId}).subscribe(res => {
      if (res.error) {
        // Alert Error
      } else {
        if (res.value?.profile) {
          this.user.set(res.value.profile);
        }
      }
    })
  }
}
